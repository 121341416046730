import {
  InstrumentAction,
  InstrumentEvent,
  InstrumentStatus,
  InstrumentTagType,
  InstrumentUploaderType,
} from '@finverity/graphql';
import { InstrumentApprovalCheckerDataGridColId } from '@finverity/platform-app/modules/instrument-approval/features/instrument-approval-checker-container/constants';
import { FinButtonAppearance } from '@finverity/ui-kit';

import { InstrumentApprovalCheckerSubscriptionEvents, InstrumentApprovalConfigs } from '../../../types';

export enum SellerFinanceRequestCheckerFilterIdentifier {
  SellerFinanceRequestChecker = 'PENDING_SELLER_FINANCE_REQUEST_CHECKER',
  NotFundable = 'NOT_FUNDABLE',
}
export const SELLER_INSTRUMENT_FINANCE_REQUEST_CHECKER_CONFIGS: InstrumentApprovalConfigs = {
  companyType: InstrumentUploaderType.Seller,
  pageTitle: 'Seller Finance Request (Checker)',
  actionsDefs: [
    {
      buttonText: 'Review & Approve',
      buttonSvgIconName: 'fvIconInstrumentApprovalReview',
      buttonAppearance: FinButtonAppearance.Primary,
      actionType: InstrumentAction.SellerFinanceCheckerApprove,
      disabled: true,
      disabledTooltip: null,
    },
    {
      buttonText: 'Reject',
      buttonSvgIconName: 'fvIconInstrumentApprovalReject',
      buttonAppearance: FinButtonAppearance.OutlineAccent,
      actionType: InstrumentAction.SellerFinanceCheckerReject,
      disabled: true,
    },
  ],
  filtersDefs: [
    {
      identifier: SellerFinanceRequestCheckerFilterIdentifier.SellerFinanceRequestChecker,
      label: 'Pending Seller Finance Request (Checker)',
      params: {
        statuses: [InstrumentStatus.SellerFinancePendingCheckerConfirmation],
        eventType: InstrumentEvent.SellerFinanceMakerApproved,
        tags: {
          notIncludes: [
            InstrumentTagType.Deleted,
            InstrumentTagType.NotFundable,
            InstrumentTagType.NoEligibleFundingWindow,
          ],
        },
      },
      count: 0,
      hiddenColumns: [],
    },
    {
      identifier: SellerFinanceRequestCheckerFilterIdentifier.NotFundable,
      label: 'Not Fundable',
      params: {
        statuses: [InstrumentStatus.SellerFinancePendingCheckerConfirmation],
        eventType: InstrumentEvent.SellerFinanceMakerApproved,
        tags: {
          notIncludes: [InstrumentTagType.Deleted],
          includes: [InstrumentTagType.NotFundable, InstrumentTagType.NoEligibleFundingWindow],
        },
      },
      count: 0,
      hiddenColumns: [
        InstrumentApprovalCheckerDataGridColId.DisbursementDate,
        InstrumentApprovalCheckerDataGridColId.Tenor,
        InstrumentApprovalCheckerDataGridColId.RepaymentDate,
        InstrumentApprovalCheckerDataGridColId.AdvanceRate,
        InstrumentApprovalCheckerDataGridColId.PrincipalAmount,
        InstrumentApprovalCheckerDataGridColId.DisbursementAmount,
        InstrumentApprovalCheckerDataGridColId.RepaymentAmount,
        InstrumentApprovalCheckerDataGridColId.NetUnfinancedBalance,
        InstrumentApprovalCheckerDataGridColId.SellerFeesInAdvance,
        InstrumentApprovalCheckerDataGridColId.PayerFeesInAdvance,
        InstrumentApprovalCheckerDataGridColId.SellerFeesInArrears,
        InstrumentApprovalCheckerDataGridColId.PayerFeesInArrears,
        InstrumentApprovalCheckerDataGridColId.TotalSellerFees,
        InstrumentApprovalCheckerDataGridColId.TotalPayerFees,
        InstrumentApprovalCheckerDataGridColId.TotalFees,
      ],
    },
  ],
  subscriptionEvents: <InstrumentApprovalCheckerSubscriptionEvents>{
    approvedSuccess: [InstrumentEvent.FunderApprovalMakerPending],
    approvedFailure: [InstrumentEvent.SellerFinanceCheckerPending],
    rejected: [InstrumentEvent.SellerFinanceCheckerRejected],
    removed: [InstrumentEvent.InstrumentDeleted],
    updated: [InstrumentEvent.InstrumentEdited],
  },
};
